<template>
  <div class="container">
    <Navbar />
    <ChatWindow />
    <NewChatFrm />
  </div>
</template>

<script>
import  NewChatFrm  from '@/components/NewChatFrm.vue';
import  ChatWindow  from '@/components/ChatWindow.vue';

import Navbar from '@/components/Navbar.vue'
import GetUser from '@/Composables/GetUser';
import { useRouter } from 'vue-router';
import { watch } from 'vue';


export default {
  components: { Navbar, NewChatFrm, ChatWindow},
  setup(){
    const {user} = GetUser();
    const router = useRouter();
    // use watch to watch over a value
    watch(user, () =>{
      if(!user.value){
        router.push({name:'Welcome'});
      }
    })

  }
}
</script>

<style>

</style>