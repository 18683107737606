
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyARnleI-OfaDTCETLFBdkdBMvmnAeRbEKY",
  authDomain: "live-chat-bb3c6.firebaseapp.com",
  projectId: "live-chat-bb3c6",
  storageBucket: "live-chat-bb3c6.appspot.com",
  messagingSenderId: "929406808774",
  appId: "1:929406808774:web:ed3995a3b6e0033b289dc7"
};

firebase.initializeApp(firebaseConfig)


const projectAuth = firebase.auth()
const projectFireStore = firebase.firestore();
const timeStamp = firebase.firestore.FieldValue.serverTimestamp();

export {projectFireStore, timeStamp, projectAuth}