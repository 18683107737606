<template>
  <nav v-if="user">
    <div>
        <p>{{ user.displayName }}</p>
        <p class="email">current login in as {{ user.email }}</p>
    </div>
    <button @click="handleClick">Logout</button>
  </nav>
</template>

<script>
import useLogut from '@/Composables/useLogout';
import GetUser from '@/Composables/GetUser';
import { useRouter } from 'vue-router';


export default {
    setup(props, context){
        const {error, logout} = useLogut();
        const {user} = GetUser();
        const router = useRouter();

        const handleClick = async () =>{
            await logout();
        }

        return {handleClick, user}
    }
}
</script>

<style>
nav{
    padding: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav p{
    margin: 2px auto;
    font-size: 16px;
    color: #444;
    text-align: start;
}
nav p.emal{
    font-size: 14px;
    color: #999;
    text-align: start;
}
</style>