<template>
    <form @submit.prevent="Login">
        <div class="error">{{ error }}</div>
        <input type="email" required placeholder="Email" v-model="Email" />
        <input type="password" required placeholder="Password" v-model="Password" />
        <button>Sign up</button>
    </form>
</template>

<script>
import { ref } from 'vue';
import useLogin from '@/Composables/useLogin';


    export default{
        setup(props, context){

            const {error, login} = useLogin();

            // refs
            const Email = ref('');
            const Password = ref('');


            const Login = async () =>{
                await login(Email.value, Password.value);
                if(!error.value){
                    console.log('loged in');
                    context.emit('login')
                }
            }


            return {Email, Password, Login, error}
        }
    }
</script>

<style lang="scss" scoped>

</style>