import {ref} from 'vue'
import { projectAuth } from '@/Firebase/config'

const error = ref(null);

const login = async (email, password) =>{
    error.value = null;

    try{
        const response = await projectAuth.signInWithEmailAndPassword(email, password);
        if(!response){throw new Error('Response error')}
        error.value = null
        return response
    }
    catch(err){
        error.value = 'Incorrect email or password !'
    }
}

const useLogin = () =>{
    return {error, login}
}

export default useLogin