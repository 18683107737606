import { ref, watchEffect } from "vue";
import { projectFireStore } from "@/Firebase/config";

const getCollection = (collection) =>{
    const document = ref(null)
    const error = ref(null)

    let collectionRef = projectFireStore.collection(collection)
        .orderBy('createdAt')

    const unsub = collectionRef.onSnapshot((snap) => {
        let result = [];
        snap.docs.forEach(doc =>{
            // add doc data created at field to get the snap from the database and not from the local snapshot
            doc.data().createdAt && result.push({...doc.data(), id: doc.id})
        })
        document.value = result
        error.value = null
    }, (err) =>{
        console.log(err.message)
        document.value = null
        error.value = 'Could not fetch data'
    })


    watchEffect((onInvalidate) =>{
        // unsubscribe from prev collection when watcher is stopped (component unmounted)
        onInvalidate(() => unsub())
    })

    return {document, error}
}

export default getCollection