import {ref} from 'vue'
import {projectAuth} from '../Firebase/config'


const error = ref(null)

const logout = async () =>{
    error.value = null
    try{
        await projectAuth.signOut()
        error.value = null
    }
    catch(err){
        error.value = err.message
    }
}

const useLogut = () =>{
    return {error, logout}
}

export default useLogut