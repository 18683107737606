<template>
  <form>
    <textarea
    placeholder="type a message and hit enter to send"
    v-model="message"
    @keypress.enter.prevent="handleSubmit">
    </textarea>
    <div class="error">{{ error }}</div>
  </form>
</template>

<script>
import { ref } from 'vue'
import GetUser from '@/Composables/GetUser'
import { timeStamp } from '@/Firebase/config'
import UseCollection from '@/Composables/UseCollection'

export default {
    setup(){

        const message = ref('')
        const {user} = GetUser();
        const {error, addDoc} = UseCollection('messages')

        const handleSubmit = async () =>{
            const chat = {
                name : user.value.displayName,
                message: message.value,
                createdAt: timeStamp
            }
            await addDoc(chat);
            if(!error.value){
                message.value = ''
            }
        }
        return {message, handleSubmit, error}
    }
}
</script>

<style scoped>
form{
    margin: 10px;
}
textarea{
    width: 100%;
    max-width: 100%;
    margin-bottom: 6px;
    padding:10px;
    box-sizing: border-box;
    border:0;
    border-radius: inherit;
    font-family: inherit;
    outline: none;
}
</style>