import { ref } from "vue";
import { projectFireStore } from "@/Firebase/config";



const UseCollection = (collection) => {
    const error = ref(null)

    const addDoc = async (doc) => {
        error.value = null
        try{

            await projectFireStore.collection(collection).add(doc)
        }catch(err){
            console.log(err.message)
            error.value = err.message
        }
    }

    return {error, addDoc}
}


export default UseCollection