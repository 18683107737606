import {ref} from 'vue'
import { projectAuth } from '@/Firebase/config'

const user = ref(projectAuth.currentUser)

projectAuth.onAuthStateChanged(_user => {
    console.log(_user)
    user.value = _user
})


const GetUser = () =>{
    return {user}
}

export default GetUser