<template>
<div class="chat-window">
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="document" class="messages" ref="messages" >
        <div v-for="doc in formatedDocuments" :key="doc.id" class="single">
            <span class="created-at">
                {{ doc.createdAt}}
            </span>
            <span class="name">{{ doc.name }}</span>
            <span class="message">{{ doc.message }}</span>
        </div>
    </div>
</div>
</template>

<script>
import getCollection from '../Composables/getCollection'
import { formatDistanceToNow } from 'date-fns'
import { computed, onUpdated, ref} from 'vue'

export default {
    setup(){
        const {error, document} = getCollection('messages')
        const formatedDocuments = computed(() =>{
            if(document.value){
                return document.value.map(doc => {
                    let time = formatDistanceToNow(doc.createdAt.toDate())
                    return {...doc, createdAt: time}
                })
            }
        })

        const messages = ref(null)

        onUpdated(() =>{
            messages.value.scrollTop = messages.value.scrollHeight;
        })

        return {error, document, formatedDocuments, messages}

    }
}
</script>

<style>
.chat-window {
    background: #fafafa;
    padding:30px 20px;
    text-align: left;
}
.single{
    margin:18px 0;
}
.created-at{
    display: block;
    color:#999;
    font-size: 12px;
    margin-bottom: 4px;
}
.name{
    font-weight: bold;
    margin-right: 6px;
}
.messages{
    max-height: 400px;
    overflow: auto;
}
</style>