<template>
    <form @submit.prevent="HandleSubmit">
        <div class="error">{{ error }}</div>
        <input type="text" required placeholder="Display name" v-model="DisplayName" />
        <input type="email" required placeholder="Email" v-model="Email" />
        <input type="password" required placeholder="Password" v-model="Password" />
        <button>Sign up</button>
    </form>
</template>

<script>
import { ref } from 'vue';
import useSignup from '@/Composables/useSignup';
    export default{
        setup(props, context){
            const {error, signup} = useSignup();
            // refs
            const DisplayName = ref('');
            const Email = ref('');
            const Password = ref('');

            const HandleSubmit = async () =>{
               await signup(Email.value, Password.value, DisplayName.value);
               if(!error.value){
                    console.log('signed up')
                    context.emit('signUp')
               }
            }

            return {DisplayName, Email, Password, HandleSubmit, error}
        }
    }
</script>

<style lang="scss" scoped>

</style>