<template>
    <div class="welcome container">
        <p>welcome</p>
        <div v-if="showLogin" >
            <h2>Login</h2>
            <LoginForm @login="enterChatRoom" />
            <p>No Account yet <span @click="showLogin=false">Signup</span> instead</p>
        </div>
        <div v-else>
            <h2>Signup</h2>
            <SignupFrom @signUp="enterChatRoom" />
            <p>Already registered ? <span @click="showLogin=true">Login</span> instead</p>

        </div>
    </div>
</template>

<script>
import SignupFrom from '../components/SignupForm.vue'
import LoginForm from '../components/LoginForm.vue'
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';



export default{
    components: {SignupFrom, LoginForm},
    setup(){
        const showLogin = ref(true);
        const router = useRouter()

        const enterChatRoom = () =>{
            console.log("Entering chat room");
            router.push({name:'chatroom'})
        }

        return {showLogin, enterChatRoom}
    }
}
</script>

<style>
.welcome {
    text-align:center;
    padding: 20px 0;
}

.welcome form{
    width: 300px;
    margin: 20px auto;
}

.welcome lable{
    display: block;
    margin: 20px 0 10px;
}

.welcome input{
    width: 100%;
    padding:10px;
    border-radius: 20px;
    border:1px solid #eee;
    outline: none;
    color:#999;
    margin: 10px auto;
}
.welcome span{
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.welcome button{
    margin: 20px auto;
}


</style>